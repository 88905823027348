import React from "react";
import "./index.css";

const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer-copy">
				&copy; Midwest Group, All Rights Reserved.
			</div>
		</footer>
	);
};

export default Footer;
